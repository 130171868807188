import { Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Image, VStack } from "@chakra-ui/react";
import { WALLETS } from "../Constants";
import AccountStore from "../store/AccountStore";
import { Wallet } from "../utils/Interfaces";

export default (props: {
    isOpen: boolean,
    onClose: () => void,
}) => {

    const _connect = (walletName: string) => {
        AccountStore.actions.connect(walletName);
        props.onClose();
    }

    return (
        <Drawer
            isOpen={props.isOpen}
            placement='bottom'
            onClose={props.onClose}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader fontSize={"md"}></DrawerHeader>

                <DrawerBody>
                    <Center>
                        <VStack gap={2} w={{ base: "100%", lg: "500px" }} pb={10}>
                        {
                            Object.keys(WALLETS).map(walletName =>
                                <WalletListItem key={walletName} onClick={() => _connect(walletName)} wallet={WALLETS[walletName]}/>
                            )
                        }
                        </VStack>
                    </Center>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

const WalletListItem = (props: {
    wallet: Wallet
    onClick: Function
}) => {
    return (
        <Button 
            width={"100%"}
            size={"lg"}
            backgroundColor={props.wallet.backgroundColor}
            color={props.wallet.textColor}
            onClick={() => props.onClick()}
            leftIcon={<Image h={props.wallet.logoHeight} src={props.wallet.logo} />}
        >
            {props.wallet.name}
        </Button>
    );
}