import { useMemo } from "react";
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';
import { Box } from "@chakra-ui/react";

export const Avatar = (props: {
    size: number,
    seed: string
}) => {
    const avatar = useMemo(() => {
        return createAvatar(identicon, {
            size: props.size,
            seed: props.seed
        }).toDataUriSync();
    }, []);

    return (
        <Box backgroundColor={'#fff'} p={0.5} borderRadius={'sm'} shadow={'base'}>
            <img src={avatar} alt="Avatar" />
        </Box>
    );
}