import { Center, Text, Box, Flex, Button, VStack } from "@chakra-ui/react";
import {
    POOL_STATUS_PROGRESS,
    POOL_STATUS_CLOSED, POOL_STATUS_SKIPPED, POOL_STATUS_OPEN, POOL_STATUS_SUSPENDED
} from "../Constants";
import { useTranslation } from "react-i18next";
import { useHookstate } from "@hookstate/core";
import PoolStore from "../store/PoolStore";
import { Countdown } from "./Countdown";

export const PoolStatus = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const pool_status = useHookstate(PoolStore.state.nested(props.pool_id).status).get();

    return (
        <Box height={"100%"}>
            <Flex width={"100%"} height={"100%"} alignContent={"center"} justifyContent={"center"}>
                <Center>
                    {
                        pool_status === POOL_STATUS_OPEN &&
                        <Countdown pool_id={props.pool_id} text={t('entryStopsIn')} />
                    }
                    {
                        pool_status === POOL_STATUS_PROGRESS &&
                        <Countdown pool_id={props.pool_id} text={t('poolResultIn')} />
                    }
                    {pool_status === POOL_STATUS_CLOSED &&
                        <Text textAlign={"center"} >{t('closedMessage')}</Text>
                    }
                    {pool_status === POOL_STATUS_SKIPPED &&
                        <Text textAlign={"center"} >{t('skippedMessage')}</Text>
                    }
                    {pool_status === POOL_STATUS_SUSPENDED &&
                        <Text textAlign={"center"} >{t('suspendedMessage')}</Text>
                    }
                </Center>
            </Flex>
        </Box>
    )
}