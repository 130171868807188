import { Box, Text } from "@chakra-ui/layout"
import { RPC_NODE, VERSION } from "../Constants"

export const Footer = () => {
    return (
        <Box p={4} textAlign={'center'}>
            <Text fontSize={'small'} fontWeight={'bold'}>{VERSION}</Text>
            <Text color={'gray.400'} fontSize={'small'}>{RPC_NODE.replace('https://', '')}</Text>
        </Box>
    )
}