import { useHookstate } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import EntryStore from "../store/EntryStore";
import {formatCurrency, getContractObj} from "../utils/Functions";
import { Text, VStack, Box } from "@chakra-ui/react";
import PoolStore from "../store/PoolStore";

export const EntryLoss = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const entry = useHookstate(EntryStore.state.nested(props.pool_id)).get();
    const entry_currency = useHookstate(PoolStore.state.nested(props.pool_id).entry_currency).get();
    
    return (
        <VStack>
            <Box>
                <Text fontSize={'sm'} color={'gray.400'}>{t('loss')}</Text>
                <Text
                    fontSize={'lg'}
                    fontWeight={"bold"}
                >
                    -{formatCurrency(entry?.amount)} {getContractObj(props.pool_id).entryCurrency.symbol}
                </Text>
            </Box>
        </VStack>
    )
}