import { Text, Box, HStack, Icon } from "@chakra-ui/react";
import {
    POOL_STATUS_CLOSED,
    POOL_STATUS_SKIPPED,
    POOL_STATUS_OPEN,
    POOL_STATUS_PROGRESS,
    POOL_STATUS_SUSPENDED
} from "../Constants";
import { useTranslation } from "react-i18next";
import { FiClock, FiDisc } from "react-icons/fi";
import { nextPoolIn } from "../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PoolTitle } from "./PoolTitle";
import { Pool } from "../utils/Interfaces";
import { IoIosRocket } from "react-icons/io";


export const PoolListRow = (props: {
    pool: Pool
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isClickable = [POOL_STATUS_OPEN, POOL_STATUS_PROGRESS].includes(props.pool.status);

    return (
        <HStack
            width={"100%"}
            onClick={() => {
                if (!isClickable) return;
                navigate(`/pool/${props.pool.pool_id}`)
            }}
            cursor={isClickable ? "pointer" : "auto"}
            shadow={"dark-lg"}
            borderRadius={'md'}
            backgroundColor={'whiteAlpha.100'}
            opacity={props.pool.status === POOL_STATUS_SUSPENDED ? 0.5 : 1}
            p={3}
            justify={"space-between"}
        >
            <PoolTitle
                pool_id={props.pool.pool_id}
                poolDuration={props.pool.duration}
                base_currency={props.pool.base_currency}
                quote_currency={props.pool.quote_currency}
            />

            <Box>
                {[POOL_STATUS_OPEN, POOL_STATUS_PROGRESS].includes(props.pool.status) &&
                    <HStack width={20} borderWidth={1} borderRadius={4} borderColor={"red.500"} paddingRight={1} paddingLeft={1} paddingTop={0.5} paddingBottom={0.5} justifyContent={"center"} gap={1}>
                        <Icon color={"red.500"} as={FiDisc} size={15}/>
                        <Text color={"red.500"} fontWeight={"bold"} fontSize={10}>{t('live').toUpperCase()}</Text>
                    </HStack>
                }
                {[POOL_STATUS_CLOSED, POOL_STATUS_SKIPPED].includes(props.pool.status) &&
                    <HStack width={20} borderWidth={1} borderRadius={4} borderColor={"yellow.500"} paddingRight={1} paddingLeft={1} paddingTop={0.5} paddingBottom={0.5} justifyContent={"center"} gap={1}>
                        <Icon color={"yellow.500"} as={FiClock} size={15}/>
                        <Text color={"yellow.500"} fontWeight={"bold"} fontSize={10}>{nextPoolIn(props.pool.end_timestamp, t).toUpperCase()}</Text>
                    </HStack>
                }
                {props.pool.status === POOL_STATUS_SUSPENDED &&
                    <HStack width={20} borderWidth={1} borderRadius={4} borderColor={"white"} paddingRight={1} paddingLeft={1} paddingTop={0.5} paddingBottom={0.5} justifyContent={"center"} gap={1}>
                        <Icon color={"white"} as={IoIosRocket} size={15}/>
                        <Text color={"white"} fontWeight={"bold"} fontSize={10}>SOON</Text>
                    </HStack>
                }
            </Box>
        </HStack>
    );
}