import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, HStack, IconButton, useDisclosure } from "@chakra-ui/react"
import React from 'react';
import { ReactElement } from "react";
import TradingViewWidget from "./TradingViewWidget";
import { FiX } from "react-icons/fi";
import { PoolTitle } from "./PoolTitle";
import { useHookstate } from "@hookstate/core";
import PoolStore from "../store/PoolStore";


export const Chart = (props: {
    button: ReactElement,
    pool_id: string
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const poolDuration = useHookstate(PoolStore.state.nested(props.pool_id).duration).get();
    const pool_base_currency = useHookstate(PoolStore.state.nested(props.pool_id).base_currency).get();
    const pool_quote_currency = useHookstate(PoolStore.state.nested(props.pool_id).quote_currency).get();

    return (
        <>
            {
                React.cloneElement(props.button, {
                    onClick: onOpen
                })
            }

            <Drawer
                isOpen={isOpen}
                placement="bottom"
                onClose={onClose}
                isFullHeight={false}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <HStack justifyContent={"space-between"} pr={4} pl={4} pt={3} pb={3}>
                        <PoolTitle 
                            pool_id={props.pool_id} 
                            poolDuration={poolDuration}
                            base_currency={pool_base_currency} 
                            quote_currency={pool_quote_currency}
                        />
                        
                        <IconButton
                            variant="outline"
                            aria-label="Close"
                            icon={<FiX />}
                            width={10}
                            height={10}
                            onClick={onClose}
                            borderRadius={"50%"}
                        />
                    </HStack>

                    <DrawerBody>
                        <TradingViewWidget pool_id={props.pool_id}/>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}