import { Box, Flex } from "@chakra-ui/layout"
import Bg1 from '../assets/bg1.png'
import Header from "./Header"
import { Footer } from "./Footer"
import { IconButton } from "@chakra-ui/react"
import { FiHelpCircle } from "react-icons/fi"
import { Help } from "./Help"
import { LangSwitcher } from "./LangSwitcher"

export const Wrapper = (props: {
    children?: React.ReactNode,
    header?: boolean,
    footer?: boolean,
    help?: boolean
}) => {
    return (
        <Flex
            direction={'column'}
            backgroundImage={`url(${Bg1})`}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
            backgroundPosition={'top center'}
            minHeight={"100vh"}
        >
            {props.header !== false && <Header />}
            <Box
                my={'auto'}
                mx={'auto'}
                w={{ base: "90%", sm: "90%", md: "500px", lg: "500px" }}
                minW={"300px"}
            >
                {props.children}
            </Box>

            {props.help !== false && <HelpButton />}
            
            <LangButton />

            {props.footer !== false && <Footer />}
        </Flex>
    )
}

const HelpButton = () => {
    return (
        <Box position={'fixed'} bottom={4} right={4}>
            <Help button={(
                <IconButton aria-label="help" style={{ borderRadius: '50%', width: 40, height: 40 }} icon={<FiHelpCircle />} fontSize={30} />
            )} />
        </Box>
    )
}

const LangButton = () => {
    return (
        <Box position={'fixed'} bottom={4} left={4}>
            <LangSwitcher />
        </Box>
    )
}