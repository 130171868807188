import { ChainIds, Methods, WebWalletConnectKoinos } from "@armana/walletconnect-koinos-sdk-js"
import { WALLET_CONNECT_PROJECT_ID } from "../Constants";
import { WalletAdapter } from "./Interfaces";

const WCK = new WebWalletConnectKoinos(
    {
        projectId: WALLET_CONNECT_PROJECT_ID,
        metadata: {
            name: "Kuku Games",
            description: "Fee-less games",
            url: "kuku.games",
            icons: [
                "https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/91f375b4-6a60-41d8-c521-5db4e3ec7b00/md",
            ],
        },
        modalOptions: {
            explorerRecommendedWalletIds: [WALLET_CONNECT_PROJECT_ID]
        }
    }
);

const walletConnectAdapter : WalletAdapter = {

    async init(config) {
        if (config?.onSessionDelete) {
            WCK.web3Modal.onSessionDelete(() => config.onSessionDelete);
        }

        if (config?.onSessionExpire) {
            WCK.web3Modal.onSessionExpire(() => config.onSessionExpire);
        }
    },

    async login() {
        const session = await WCK.web3Modal.getSession();
        if (session) {
            return true;
        }

        return false;
    },

    async connect() {
        try {
            // initiate connection with a wallet
            const accounts = await WCK.connect(
                [process.env.REACT_APP_RPC_NODE && process.env.REACT_APP_RPC_NODE.includes("harbinger") ? ChainIds.Harbinger : ChainIds.Mainnet],
                [
                    Methods.ReadContract,
                    Methods.SignAndSendTransaction,
                    Methods.PrepareTransaction,
                    Methods.WaitForTransaction,
                    Methods.GetNextNonce,
                    Methods.GetAccountRc,
                    Methods.GetChainId
                ]
            );

            if (accounts.length >= 1) {
                return accounts[0];
            }
            
        } catch (e) {
            console.error(e);
        }

        return null;
    },

    async disconnect() {
        try {
            await WCK.disconnect();
            return true;
        } catch (e) {
            console.error(e);
        }

        return false;
    },

    getSigner(address: string) {
        return WCK.getSigner(address);
    },
}

export default walletConnectAdapter;