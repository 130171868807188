import { ChakraProvider } from "@chakra-ui/react"
import { Routes, Route } from "react-router-dom";
import Pool from "./pages/Pool";
import theme from "./Theme";
import '@fontsource/comfortaa';
import { useEffect } from "react";
import GlobalStore from "./store/AccountStore";
import { PageNotFound } from "./pages/PageNotFound";
import {Entries} from "./pages/Entries";
import { Maintenance } from "./pages/Maintenance";
import {MAINTENANCE_MODE} from "./Constants";
import {Home} from "./pages/Home";

let loaded = false;

export const App = () => {

  useEffect(() => {
    if (!loaded) {
      GlobalStore.actions.init();
      loaded = true;
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
    { MAINTENANCE_MODE &&
        <Routes>
          <Route path="*" element={<Maintenance />} />
        </Routes>
    }
    { !MAINTENANCE_MODE &&
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={"/pool/:id"} element={<Pool />} />
          <Route path={"/entries"} element={<Entries />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
    }
    </ChakraProvider>
  )

}
