import { Button } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import AccountStore from "../store/AccountStore";
import { useNavigate } from "react-router-dom";
import { MdHistory } from "react-icons/md";


export const HistoryButton = () => {
    const { t } = useTranslation();
    const account = useHookstate(AccountStore.state.account).get();
    const navigate = useNavigate();

    if (!account) {
        return <></>
    }

    return (<Button leftIcon={<MdHistory size={20}/>} onClick={() => navigate(`/entries`)}>{t('history')}</Button>);
}