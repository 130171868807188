import { Box, Text, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Step, StepDescription, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useDisclosure, useSteps, VStack } from "@chakra-ui/react"
import React from "react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export const Help = (props: {
    button: ReactElement,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();

    return (
        <>
            {
                React.cloneElement(props.button, {
                    onClick: onOpen
                })
            }

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader color={"teal.200"}>{t('howItWorks')}</DrawerHeader>

                    <DrawerBody>
                        <VStack spacing={8} alignItems={"left"}>
                            <Text>
                                {t('howItWorksDescription')}
                            </Text>
                            <Steps />
                            <Text color={"pink.400"}>
                                {t('howItWorksDescription2')}
                            </Text>
                            <Text color={"pink.400"}>
                                {t('fees')}
                            </Text>
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

const Steps = () => {
    const { t } = useTranslation();

    const steps = [
        { title: t('step1Title'), description: t('step1Description') },
        { title: t('step2Title'), description: t('step2Description') },
        { title: t('step3Title'), description: t('step3Description') },
        { title: t('step4Title'), description: t('step4Description') },
    ];

    const { activeStep } = useSteps({
        index: 3,
        count: steps.length,
    });

    return (
        <Stepper index={activeStep} orientation="vertical" height="300px" gap="0">
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepNumber />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink="0">
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    )
}