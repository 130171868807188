import { hookstate } from "@hookstate/core"
import {Provider, Contract, utils} from "koilib"
import walletConnectAdapter from "../utils/WalletConnectAdapter";
import {RPC_NODE} from "../Constants";
import { Account, WalletAdapter } from "../utils/Interfaces";
import KoinContractAbi from "../utils/KoinContractAbi";
import {getContractObj} from "../utils/Functions";
import kondorAdapter from "../utils/KondorAdapter";

const state = hookstate<Account>({
    account: undefined,
    balance: 0
});

let walletAdapter : WalletAdapter;


const WALLET_ADAPTER_MAP : Record<string, WalletAdapter> = {
    WALLET_CONNECT: walletConnectAdapter,
    KONDOR: kondorAdapter
};

const actions = {
    init: async () => {
        const config = {
            onSessionDelete: () => state.account.set(undefined),
            onSessionExpire: () => state.account.set(undefined)
        }

        for (const walletAdapterName in WALLET_ADAPTER_MAP) {
            const walletAdapter = WALLET_ADAPTER_MAP[walletAdapterName];
            await walletAdapter.init(config);
            if (await walletAdapter.login()) {
                await actions.connect(walletAdapterName);
                return;
            }
        }
    },

    connect: async (walletName: string) => {
        const adapter = WALLET_ADAPTER_MAP[walletName];

        const address = await adapter.connect();
        if (address) {
            state.account.set(address);
            walletAdapter = adapter;
        }
    },

    disconnect: async () => {
        const disconnected = await walletAdapter.disconnect()
        if (disconnected) {
            state.account.set(undefined);
        }
    },

    async refreshBalance(poolId: string) {
        const contractId = getContractObj(poolId).entryCurrency.contractId;
        const tokenDecimal = getContractObj(poolId).entryCurrency.decimal;
        const balance = await getters.getBalance(contractId, tokenDecimal);
        state.balance.set(balance);
    }
};

const getters = {
    getSigner: () => {
        if (state.account.ornull) {
            const signer = walletAdapter.getSigner(state.account.ornull.get());
            signer.provider = getters.getProvider();
            return signer;
        }
        
        return null;
    },

    getProvider: () => {
        return new Provider([RPC_NODE]);
    },

    getAccount: () => {
        return state.account.get();
    },

    getBalance: async (contractId: string, tokenDecimal: number) => {
        const provider = getters.getProvider();
        const signer = getters.getSigner();

        if (signer) {
            signer.provider = provider;
            const contract = new Contract({
                id: contractId,
                abi: KoinContractAbi,
                provider,
                signer,
            });

            //contract.abi = await contract.fetchAbi();
    
            const { result } = await contract.functions.balanceOf({
                owner: signer.getAddress(),
            });

            if (result && result.value) {
                return parseFloat(utils.formatUnits(result.value, tokenDecimal));
            }
        }

        return 0;
    },

}

export default {
    state,
    actions,
    getters
}