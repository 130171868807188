import { VStack, HStack, Icon, Box, Text } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
import { ENTRY_SIDE_LONG } from "../Constants";
import EntryStore from "../store/EntryStore";
import {formatCurrency, getContractObj} from "../utils/Functions";
import PoolStore from "../store/PoolStore";

export const EntryDetail = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const entry = useHookstate(EntryStore.state.nested(props.pool_id)).get();
    const entry_currency = useHookstate(PoolStore.state.nested(props.pool_id).entry_currency).get();

    return (
        <VStack>
            <Box>
                <Text fontSize={'sm'} color={'gray.400'}>{t("yourEntry")}</Text>
                <HStack gap={1}>
                    {entry.side === ENTRY_SIDE_LONG ?
                        <Icon as={FiArrowUp} color={'green.400'} boxSize={5} /> :
                        <Icon as={FiArrowDown} color={'red.400'} boxSize={5} />
                    }
                    <Text
                        fontSize={'lg'}
                        fontWeight={"bold"}
                    >
                        {formatCurrency(entry?.amount)} {getContractObj(props.pool_id).entryCurrency.symbol}
                    </Text>
                </HStack>
            </Box>
        </VStack>
    );
}