import { VStack } from "@chakra-ui/react";
import { Wrapper } from "../components/Wrapper";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PoolBox from "../components/PoolBox";
import PoolStore from "../store/PoolStore";
import { POOL_STATUS_OPEN, POOL_STATUS_PROGRESS, POLLING_INTERVAL_GET_POOL } from "../Constants";

export default function Pool() {
    const { id } = useParams();
    const [pool_status, setPoolStatus] = useState('');

    const loadPool = async () => {
        if (id) {
            await PoolStore.actions.refreshPool(id);
            const status = PoolStore.state.nested(id)?.status?.get();

            if (status && status != pool_status) {
                setPoolStatus(status);
            }
        }
    };

    useEffect(() => {
        loadPool();
    }, []);

    useEffect(() => {
        if (pool_status === POOL_STATUS_OPEN || pool_status === POOL_STATUS_PROGRESS) {
            const refreshPoolInterval = setInterval(() => {
                loadPool();
            }, POLLING_INTERVAL_GET_POOL);

            return () => clearInterval(refreshPoolInterval);
        }
    }, [pool_status]);


    if (!id || !pool_status) {
        return (
            <Wrapper>
                <VStack>
                    Missing pool in url
                </VStack>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <PoolBox pool_id={id} />
        </Wrapper>
    );
}