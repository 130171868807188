import { VStack } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import EntryStore from "../store/EntryStore";
import { EstimatedProfitDetail } from "./EstimatedProfitDetail";

export const EntryProgress = (props: {
    pool_id: string
}) => {
    const entry = useHookstate(EntryStore.state.nested(props.pool_id)).get();
    
    return (
        <VStack>
            <EstimatedProfitDetail pool_id={props.pool_id} side={entry.side}/>
        </VStack>
    );
}