import { useEffect, useRef, memo } from 'react';
import { getContractObj } from '../utils/Functions';
import { useTranslation } from 'react-i18next';

const TradingViewWidget = (props: {
    pool_id: string
}) => {
    const { i18n } = useTranslation();
    const container = useRef<HTMLInputElement | null>(null);
    const { tradingView } = getContractObj(props.pool_id);

    useEffect(
        () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
                {
                    "autosize": true,
                    "symbol": "${tradingView.symbol}",
                    "interval": "${tradingView.interval}",
                    "timezone": "Etc/UTC",
                    "theme": "dark",
                    "style": "1",
                    "locale": "${i18n.language}",
                    "enable_publishing": false,
                    "hide_legend": true,
                    "save_image": false,
                    "calendar": false,
                    "support_host": "https://www.tradingview.com"
                }`;

            if (container?.current && container.current.childNodes.length === 2) {
                container.current.appendChild(script);
            }
        },
        [props.pool_id]
    );

    return (
        <div style={{height: '40vh'}}>
        <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
            <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
            <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
        </div>
        </div>
    );
}

export default memo(TradingViewWidget);
