import { Text, useDisclosure, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Center, ModalFooter, Box, Flex, Divider } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useState } from "react";
import { ENTRY_SIDE_LONG, ENTRY_SIDE_SHORT } from "../Constants";
import AccountStore from "../store/AccountStore";
import { EstimatedProfitDetail } from "./EstimatedProfitDetail";
import { useTranslation } from 'react-i18next';
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
import EntryStore from "../store/EntryStore";
import {formatCurrency, getContractObj, getRpcError} from "../utils/Functions";
import ConnectDrawer from "./ConnectDrawer";


export const EntryButtonsAndModal = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const [amount, setAmount] = useState('1');
    const [side, setSide] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const balance = useHookstate(AccountStore.state.balance).get();
    const account = useHookstate(AccountStore.state.account).get();
    const [transactionProgressMessage, setTransactionProgressMessage] = useState(t("loading"));
    const entryDisclosure = useDisclosure();
    const toast = useToast();
    const connectDisclosure = useDisclosure();

    const openWithSide = (side: string) => {
        setSide(side);
        entryDisclosure.onOpen();
    };

    const placeEntry = async () => {
        try {
            setIsLoading(true);

            setTransactionProgressMessage(t("waitingWalletSign"));

            const { transaction, receipt } = await EntryStore.actions.entry({
                pool_id: props.pool_id,
                amount: amount,
                side: side
            });

            if (!transaction || !receipt) {
                throw "No transaction or receipt";
            }

            EntryStore.actions.notify({
                pool_id: props.pool_id,
                amount: amount,
                side: side
            });

            setTransactionProgressMessage(t("waitingTransactionMining"));
            await EntryStore.actions.confirmTransaction({
                pool_id: props.pool_id,
                transaction: transaction,
                receipt: receipt
            });

            toast({
                description: t("transactionMined"),
                status: 'success'
            });
        } catch (error: any) {
            console.log(error);
            const rcpError = getRpcError(error);
            toast({
                description: (rcpError.error ? rcpError.error : error.toString()),
                status: 'error'
            });
        } finally {
            entryDisclosure.onClose();
            setIsLoading(false);
            setTransactionProgressMessage(t("loading"));
        }
    };

    return (

        <>
            <Box>
                <Flex justifyContent={'space-entryween'} gap={4}>
                    <Box w={"50%"}>
                        <Box p={4}>
                            <EstimatedProfitDetail side={ENTRY_SIDE_LONG} pool_id={props.pool_id}/>
                        </Box>
                        <Button
                            leftIcon={<FiArrowUp />}
                            variant='outline'
                            w={"100%"}
                            borderColor={'green.400'}
                            color={'green.400'}
                            onClick={!account ? () => connectDisclosure.onOpen() : () => openWithSide(ENTRY_SIDE_LONG)}
                        >
                            {t('enterLong')}
                        </Button>
                    </Box>

                    <Center>
                        <Divider orientation='vertical' />
                    </Center>

                    <Box w={"50%"}>
                        <Box p={4}>
                            <EstimatedProfitDetail side={ENTRY_SIDE_SHORT} pool_id={props.pool_id}/>
                        </Box>
                        <Button
                            leftIcon={<FiArrowDown />}
                            variant='outline'
                            w={"100%"}
                            borderColor={'red.400'}
                            color={'red.400'}
                            onClick={!account ? () => connectDisclosure.onOpen() : () => openWithSide(ENTRY_SIDE_SHORT)}
                        >
                            {t('enterShort')}
                        </Button>
                    </Box>
                </Flex>
            </Box>

            <Modal isOpen={entryDisclosure.isOpen} onClose={entryDisclosure.onClose} size={"xs"} isCentered>
                <ModalOverlay />
                <ModalContent p={2}>
                    <ModalHeader>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <Box w={'100%'} textAlign="left">
                            <form>
                                <FormControl>
                                    <FormLabel fontSize={'md'} fontWeight={'bold'} color={'gray.400'}>{t('amount')}</FormLabel>
                                    <NumberInput min={1} value={amount} onChange={(v: string) => setAmount(v)}>
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>
                                <Box textAlign={"right"}>
                                    <Text 
                                        color={"gray.400"}
                                        fontSize={'sm'} 
                                        pt={1}
                                    >
                                        {t('available')}: {formatCurrency(balance)} {getContractObj(props.pool_id).entryCurrency.symbol}
                                    </Text>
                                </Box>
                            </form>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            width='full'
                            variant='outline'
                            disabled={balance <= 0}
                            leftIcon={side === ENTRY_SIDE_LONG ? <FiArrowUp/> : <FiArrowDown />}
                            borderColor={side === ENTRY_SIDE_LONG ? 'green.400' : 'red.400'}
                            color={side === ENTRY_SIDE_LONG ? 'green.400' : 'red.400'}
                            isLoading={isLoading}
                            loadingText={t(transactionProgressMessage)}
                            onClick={placeEntry}>
                            {side === ENTRY_SIDE_LONG ? t('enterLong') : t('enterShort')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <ConnectDrawer isOpen={connectDisclosure.isOpen} onClose={connectDisclosure.onClose}/>
        </>
    )
}
