import BtcLogo from './assets/btc_usd.png';
import EthLogo from './assets/eth_usd.png';
import SolLogo from './assets/sol_usd.png';
import { KukuPredictionContract, Wallet } from './utils/Interfaces';
import WalletConnectLogo from './assets/WalletConnect.png';
import KondorLogo from './assets/Kondor.png';

export const WALLETS : Record<string, Wallet> = {
    WALLET_CONNECT: {
        name: 'Wallet Connect',
        logo: WalletConnectLogo,
        logoHeight: 18,
        backgroundColor: "#3396FF",
        textColor: "white"
    },
    KONDOR: {
        name: 'Kondor',
        logo: KondorLogo,
        logoHeight: 22,
        backgroundColor: "#7161ef",
        textColor: "white"
    }
}

export const CONTRACTS : Record<string,KukuPredictionContract> = {
    BTC_USD_14400: {
        contractId: "1PvAfM8CEnhLnkYsK4vMbNeHUK86Y6KsUB",
        logo: BtcLogo,
        tradingView: {
            symbol: "COINBASE:BTCUSD",
            interval: '240'
        },
        entryCurrency: {
            symbol: "KOIN",
            decimal: 8,
            contractId: process.env.REACT_APP_KOIN_CONTRACT_ID ?? "1FaSvLjQJsCJKq5ybmGsMMQs8RQYyVv8ju"
        }
    },
    BTC_USD_3600: {
        contractId: "1GMQ31cCuwzzkL7tMzaEk4VzAWqe2mNm7i",
        logo: BtcLogo,
        tradingView: {
            symbol: "COINBASE:BTCUSD",
            interval: '240'
        },
        entryCurrency: {
            symbol: "KOIN",
            decimal: 8,
            contractId: process.env.REACT_APP_KOIN_CONTRACT_ID ?? "1FaSvLjQJsCJKq5ybmGsMMQs8RQYyVv8ju"
        }
    },
    ETH_USD_900: {
        contractId: "1EF5oQHJPmUFnVwEdLmHxsiuv3Gvuzrymh",
        logo: EthLogo,
        tradingView: {
            symbol: "COINBASE:ETHUSD",
            interval: '15'
        },
        entryCurrency: {
            symbol: "KOIN",
            decimal: 8,
            contractId: process.env.REACT_APP_KOIN_CONTRACT_ID ?? "1FaSvLjQJsCJKq5ybmGsMMQs8RQYyVv8ju"
        }
    },
    SOL_USD_300: {
        contractId: "1a8wVRRRhRD6iYceuVyyYcCPgCZGpH3nx",
        logo: SolLogo,
        tradingView: {
            symbol: "COINBASE:SOLUSD",
            interval: '15'
        },
        entryCurrency: {
            symbol: "KOIN",
            decimal: 8,
            contractId: process.env.REACT_APP_KOIN_CONTRACT_ID ?? "1FaSvLjQJsCJKq5ybmGsMMQs8RQYyVv8ju"
        }
    },
};

export const POOL_STATUS_OPEN = 'open';
export const POOL_STATUS_CLOSED = 'closed';
export const POOL_STATUS_PROGRESS = 'progress';
export const POOL_STATUS_SKIPPED = 'skipped';
export const POOL_STATUS_SUSPENDED = 'suspended';
export const ENTRY_SIDE_LONG = 'LONG';
export const ENTRY_SIDE_SHORT = 'SHORT';
export const QUOTE_DECIMAL = 2;

export const POLLING_INTERVAL_GET_POOL = parseInt(process.env.REACT_APP_POLLING_INTERVAL_GET_POOL ?? "10000");
export const POLLING_INTERVAL_GET_TOKEN_PRICE = parseInt(process.env.REACT_APP_POLLING_INTERVAL_GET_TOKEN_PRICE ?? "10000");
export const ESTIMATED_CRON_DELAY = parseInt(process.env.REACT_APP_ESTIMATED_CRON_DELAY ?? "0");
export const ENTRIES_PAGINATION_LIMIT = parseInt(process.env.REACT_APP_ENTRIES_PAGINATION_LIMIT ?? "5");
export const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true" ? true : false;
export const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? "2d5354765633a32b60b1bad09a6fe0d4";
export const RPC_NODE = process.env.REACT_APP_RPC_NODE ?? "https://harbinger-api.koinos.io";
export const VERSION = process.env.REACT_APP_VERSION ?? "unknown";