import {
    ESTIMATED_CRON_DELAY,
    POOL_STATUS_CLOSED,
    POOL_STATUS_OPEN,
    POOL_STATUS_PROGRESS,
    POOL_STATUS_SKIPPED
} from "../Constants";

import {
    Box,
    Center,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text, useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PoolStore from "../store/PoolStore";


export const Countdown = (props: {
    pool_id: string,
    text: string 
}) => {
    const pool_end_timestamp = useHookstate(PoolStore.state.nested(props.pool_id).end_timestamp).get();

    const to = new Date(pool_end_timestamp);

    return (
        <Box textAlign={"center"}>
            <Text fontSize={'sm'} color={'gray.400'}>
                {props.text}
            </Text>

            <CD toTime={to.getTime() + ESTIMATED_CRON_DELAY}/>
        </Box>
    )
}

export const CD = (props: {
    toTime: number
}) => {
    const { t } = useTranslation();
    const [countdown, setCountdown] = useState(-1);
    const { isOpen, onOpen, onClose } = useDisclosure()

    const formatCountdown = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
    
        let formattedTime = '';
    
        if (hours > 0) {
            formattedTime += hours + 'h ';
        }
    
        if (minutes > 0 || hours > 0) {
            formattedTime += minutes + 'm ';
        }
    
        formattedTime += remainingSeconds + 's';
    
        return formattedTime.trim(); // Rimuovi spazi vuoti finali
    }


    const updateCountDown = () => {
        const from = new Date();
        const delta = Math.floor(from.getTime() <= props.toTime ? ((props.toTime - from.getTime()) / 1000) : 0);
        setCountdown(delta);
    };

    useEffect(() => {
        updateCountDown();
        const interval = setInterval(() => {
            updateCountDown()
        }, 1000);
        return () => clearInterval(interval);
    }, [props.toTime]);

    const ts = formatCountdown(countdown);

    return (
        <>
            <Box h={"100%"}>
                <Center>
                    {
                        countdown > 0 &&
                        <Box>
                            <Text fontSize={'lg'} fontWeight={'bold'} textAlign={"center"}>{ts}</Text>
                        </Box>
                    }
                    {/*
                        countdown <= 0 &&
                        <Box>
                            <Text fontWeight={'bold'} textAlign={"center"}>{t('calculating')}</Text>
                        </Box>
                    */}
                </Center>
            </Box>

            <Modal size={"xs"} isCentered isOpen={countdown <= 0} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <VStack pt={3}>
                            <Spinner/>
                            <Text p={3}>{t('calculating')}</Text>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>

    )
}