import { Flex, Box } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { POOL_STATUS_CLOSED, POOL_STATUS_SKIPPED, POOL_STATUS_PROGRESS, POOL_STATUS_OPEN, POOL_STATUS_SUSPENDED } from "../Constants";
import EntryStore from "../store/EntryStore";
import PoolStore from "../store/PoolStore";
import { EntryDetail } from "./EntryDetail";
import { EntryLoss } from "./EntryLoss";
import { EntryProgress } from "./EntryProgress";
import { EntrySkipped } from "./EntrySkipped";
import { EntryWin } from "./EntryWin";

export const EntryPlaced = (props: {
    pool_id: string
}) => {
    const entry = useHookstate(EntryStore.state.nested(props.pool_id)).get();
    const pool = useHookstate(PoolStore.state.nested(props.pool_id)).get();

    return (
        <Box h={"100%"} height={"100%"}>
            <Flex alignItems={"center"} justifyContent={'space-entryween'} height={"100%"} gap={4}>
                <Box width={"50%"}>
                    <EntryDetail pool_id={props.pool_id} />
                </Box>

                <Box width={"1px"} height={"100px"} borderRight={"solid 1px var(--chakra-colors-whiteAlpha-300)"}></Box>

                <Box width={"50%"}>
                    {pool.status === POOL_STATUS_CLOSED && entry?.winner &&
                        <EntryWin pool_id={props.pool_id} />
                    }

                    {pool.status === POOL_STATUS_CLOSED && !entry?.winner &&
                        <EntryLoss pool_id={props.pool_id} />
                    }

                    {(pool.status === POOL_STATUS_SKIPPED || pool.status === POOL_STATUS_SUSPENDED) &&
                        <EntrySkipped pool_id={props.pool_id} />
                    }

                    {(pool.status === POOL_STATUS_PROGRESS || pool.status === POOL_STATUS_OPEN) &&
                        <EntryProgress pool_id={props.pool_id} />
                    }
                </Box>
            </Flex>
        </Box>
    );
}


