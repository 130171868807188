import { useToast, VStack, Button, Box, Text } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiDollarSign } from "react-icons/fi";
import EntryStore from "../store/EntryStore";
import {getRpcError, formatCurrency, getContractObj} from "../utils/Functions";
import PoolStore from "../store/PoolStore";

export const EntryWin = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const entry = useHookstate(EntryStore.state.nested(props.pool_id)).get();
    const entry_currency = useHookstate(PoolStore.state.nested(props.pool_id).entry_currency).get();
    const [claimIsLoading, setClaimIsLoading] = useState(false);
    const [transactionProgressMessage, setTransactionProgressMessage] = useState(t("loading"));
    const toast = useToast();

    const claim = async () => {
        try {
            setClaimIsLoading(true);
            setTransactionProgressMessage(t("waitingWalletSign"));

            const { transaction, receipt } = await EntryStore.actions.reward({
                pool_id: props.pool_id,
            });

            if (!transaction || !receipt) {
                throw "No transaction or receipt";
            }

            setTransactionProgressMessage(t("waitingTransactionMining"));
            await EntryStore.actions.confirmTransaction({
                pool_id: props.pool_id,
                transaction: transaction,
                receipt: receipt
            });

            toast({
                description: t("transactionMined"),
                status: 'success'
            });
        } catch (error: any) {
            console.log(error);
            const rcpError = getRpcError(error);
            toast({
                description: (rcpError.error ? rcpError.error : error.toString()),
                status: 'error'
            });
        } finally {
            setClaimIsLoading(false);
            setTransactionProgressMessage(t("loading"));
        }
    };

    return (
        <VStack>
            <Box>
                <Text fontSize={'sm'} color={'gray.400'}>{t('reward')}</Text>
                <Text fontSize={'lg'} fontWeight={"bold"}
                >
                    +{formatCurrency(entry?.amount_reward)} {getContractObj(props.pool_id).entryCurrency.symbol}
                </Text>
            </Box>

            {!entry.rewarded &&
                <Button
                    isLoading={claimIsLoading}
                    loadingText={transactionProgressMessage}
                    variant='outline'
                    color={"pink.500"}
                    borderColor={"pink.500"}
                    leftIcon={<FiDollarSign />}
                    onClick={claim}
                >
                    {t('claim')}
                </Button>
            }
        </VStack>
    );
}