import {Button, Menu, MenuButton, MenuList, MenuItem, useToast, useDisclosure} from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import AccountStore from "../store/AccountStore";
import { Avatar } from './Avatar';
import { FiLogOut, FiCopy, FiLink } from "react-icons/fi";
import ConnectDrawer from "./ConnectDrawer";

export const ConnectButton = () => {
    const { t } = useTranslation();
    const account = useHookstate(AccountStore.state.account).get();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const copyToClipboard = (string: string) : void => {
        navigator.clipboard.writeText(string).then(function() {
            toast({
                description: t("copiedToClipboard"),
                status: 'success'
            });
        }, function(err) {
            toast({
                description: t("copiedToClipboardError"),
                status: 'error'
            });
        });
    }

    if (!account) {
        return (
            <>
                <Button onClick={() => onOpen()} leftIcon={<FiLink></FiLink>}>
                    {t('connect')}
                </Button>
                <ConnectDrawer isOpen={isOpen} onClose={onClose}/>
            </>
        );
    }

    return (
        <Menu>
            <MenuButton cursor={'pointer'}>
                <Avatar size={28} seed={account} />
            </MenuButton>
            <MenuList alignItems={'center'}>
                <MenuItem icon={<FiCopy size={20}/>} onClick={() => copyToClipboard(account)}>{account.substring(0, 5) + "..." + account.substring(account.length - 5)}</MenuItem>
                <MenuItem icon={<FiLogOut size={20}/>} onClick={AccountStore.actions.disconnect}>{t('disconnect')}</MenuItem>
                {/*<MenuItem icon={<MdHistory size={20}/>} onClick={() => navigate(`/entries`)}>{t('history')}</MenuItem>*/}
            </MenuList>
        </Menu>
    );
}