import {
    Text,
    Box,
    Stack
} from "@chakra-ui/react";
import {
    POLLING_INTERVAL_GET_TOKEN_PRICE,
    POOL_STATUS_CLOSED,
    POOL_STATUS_OPEN,
    POOL_STATUS_PROGRESS,
} from "../Constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHookstate } from "@hookstate/core";
import PoolStore from "../store/PoolStore";
import {formatCurrency, getContractObj} from "../utils/Functions";

export const PoolReport = (props: {
    pool_id: string
}) => {
    const pool_status = useHookstate(PoolStore.state.nested(props.pool_id).status).get();

    return (
        <Stack w={"100%"} h={"100%"} rowGap={3}>
            <CurrentPrice pool_id={props.pool_id} />

            {
                [POOL_STATUS_CLOSED, POOL_STATUS_PROGRESS].includes(pool_status) &&
                <PriceInitial pool_id={props.pool_id} />
            }

            {
                [POOL_STATUS_CLOSED].includes(pool_status) &&
                <PriceFinal pool_id={props.pool_id} />
            }

            {(pool_status === POOL_STATUS_OPEN || pool_status === POOL_STATUS_PROGRESS) &&
                <PoolPrice pool_id={props.pool_id} />
            }
        </Stack>
    )
}

const CurrentPrice = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const [currentPrice, setCurrentPrice] = useState(0);
    // const [priceColor, setPriceColor] = useState('');
    const pool_status = useHookstate(PoolStore.state.nested(props.pool_id).status).get();
    const pool_base_currency = useHookstate(PoolStore.state.nested(props.pool_id).base_currency);
    const pool_quote_currency = useHookstate(PoolStore.state.nested(props.pool_id).quote_currency);

    const fetchPrice = async () => {
        let price = -1;
        try {
            const response = await fetch(`https://api.coinbase.com/v2/exchange-rates?currency=${pool_base_currency.get().toUpperCase()}`);
            const data = await response.json();
            price = data.data.rates[pool_quote_currency.get().toUpperCase()]
        } catch (error) {
            console.error('Errore nella richiesta:', error)
        }
        return price;
    }

    useEffect(() => {
        // declare the data fetching function
        const fetchTokenPrice = async () => {
            const newPrice = await fetchPrice();
            setCurrentPrice(newPrice);
        }

        fetchTokenPrice().catch(console.error);
        const fetchPriceInterval = setInterval(async () => {
            fetchTokenPrice().catch(console.error);
        }, POLLING_INTERVAL_GET_TOKEN_PRICE);
        return () => clearInterval(fetchPriceInterval)
    }, [pool_base_currency, pool_quote_currency])

    return (
        <>
            {
                [POOL_STATUS_OPEN, POOL_STATUS_PROGRESS].includes(pool_status) &&
                <Box>
                    <Text fontSize={'sm'} color={'gray.400'}>{t('currentPrice')}</Text>
                    <Text sx={{ transition: "all .5s ease" }} fontWeight={"bold"}>
                        {formatCurrency(currentPrice)} {pool_quote_currency.get()}
                    </Text>
                </Box>
            }
        </>
    );
}

const PriceInitial = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const price_initial = useHookstate(PoolStore.state.nested(props.pool_id).price_initial).get();
    const pool_quote_currency = useHookstate(PoolStore.state.nested(props.pool_id).quote_currency).get();

    return (
        <Box>
            <Text fontSize={'sm'} color={'gray.400'}>{t('initialPrice')}</Text>
            <Text fontWeight={"bold"}>
                {formatCurrency(price_initial)} {pool_quote_currency}
            </Text>
        </Box>
    );
}

const PriceFinal = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const price_final = useHookstate(PoolStore.state.nested(props.pool_id).price_final).get();
    const pool_quote_currency = useHookstate(PoolStore.state.nested(props.pool_id).quote_currency).get();

    return (
        <Box>
            <Text fontSize={'sm'} color={'gray.400'}>{t('finalPrice')}</Text>
            <Text fontWeight={"bold"}>
                {formatCurrency(price_final)} {pool_quote_currency}
            </Text>
        </Box>
    );
}

const PoolPrice = (props: {
    pool_id: string
}) => {
    const { t } = useTranslation();
    const long_total = useHookstate(PoolStore.state.nested(props.pool_id).long_total).get();
    const short_total = useHookstate(PoolStore.state.nested(props.pool_id).short_total).get();
    const entry_currency = useHookstate(PoolStore.state.nested(props.pool_id).entry_currency).get();
    
    return (
        <Box>
            <Text fontSize={'sm'} color={'gray.400'}>{t('poolPrice')}</Text>
            <Text fontWeight={"bold"}>
                {long_total+short_total} {getContractObj(props.pool_id).entryCurrency.symbol}
            </Text>
        </Box>
    )
}