import { hookstate } from "@hookstate/core"
import { Contract, utils } from "koilib"
import {CONTRACTS, QUOTE_DECIMAL} from "../Constants";
import AccountStore from "./AccountStore";
import {getContractObj} from "../utils/Functions";
import { Pool } from "../utils/Interfaces";
import contractAbi from "../utils/ContractAbi";


type PoolStoreType = Record<string, Pool>;

const state = hookstate<PoolStoreType>({});

const actions = {
    refreshPool: async function (pool_id: string) {
        const pool = await getters.getPool(pool_id);

        if (pool) {
            const poolState = state.nested(pool_id);
            if (!poolState.get() || pool.status === 'UNKNOWN') {
                state.merge({
                    [pool_id]: pool
                })
            } else if (poolState) {
                if (poolState.pool_id.get() !== pool.pool_id) {
                    poolState.pool_id.set(pool.pool_id);
                }

                if (poolState.status.get() !== pool.status) {
                    poolState.status.set(pool.status);
                }

                if (poolState.long_total.get() !== pool.long_total) {
                    poolState.long_total.set(pool.long_total);
                }

                if (poolState.short_total.get() !== pool.short_total) {
                    poolState.short_total.set(pool.short_total);
                }

                if (poolState.price_initial.get() !== pool.price_initial) {
                    poolState.price_initial.set(pool.price_initial);
                }

                if (poolState.price_final.get() !== pool.price_final) {
                    poolState.price_final.set(pool.price_final);
                }

                if (poolState.start_timestamp.get() !== pool.start_timestamp) {
                    poolState.start_timestamp.set(pool.start_timestamp);
                }

                if (poolState.end_timestamp.get() !== pool.end_timestamp) {
                    poolState.end_timestamp.set(pool.end_timestamp);
                }

                if (poolState.duration.get() !== pool.duration) {
                    poolState.duration.set(pool.duration);
                }

                if (poolState.royalty.get() !== pool.royalty) {
                    poolState.royalty.set(pool.royalty);
                }

                if (poolState.base_currency.get() !== pool.base_currency) {
                    poolState.base_currency.set(pool.base_currency);
                }

                if (poolState.quote_currency.get() !== pool.quote_currency) {
                    poolState.quote_currency.set(pool.quote_currency);
                }

                if (poolState.entry_currency.get() !== pool.entry_currency) {
                    poolState.entry_currency.set(pool.entry_currency);
                }
            }
        }
    },

};

const getters = {

    getLatestPool: async function (contractName: string) : Promise<Pool|null> {
        const contractId = CONTRACTS[contractName].contractId;
        const provider = AccountStore.getters.getProvider();

        const contract = new Contract({
            id: contractId,
            abi: contractAbi,
            provider,
        });

        const res = await contract.functions.getLatestPool();
        if (res.result && res.result.value) {

            const entry_currency = res.result.value.entry_currency
            const token_decimal = CONTRACTS[contractName].entryCurrency.decimal;

            return {
                pool_id: res.result.value.pool_id,
                base_currency: res.result.value.base_currency,
                quote_currency: res.result.value.quote_currency,
                entry_currency,
                status: res.result.value.status,
                start_timestamp: parseInt(res.result.value.start_timestamp),
                end_timestamp: parseInt(res.result.value.end_timestamp),
                duration: parseInt(res.result.value.duration) ?? 0,
                royalty: parseInt(res.result.value.royalty) ?? 0,
                long_total: res.result.long_total ? parseFloat(utils.formatUnits(res.result.long_total, token_decimal)) : 0,
                short_total: res.result.short_total ? parseFloat(utils.formatUnits(res.result.short_total, token_decimal)) : 0,
                price_initial: res.result.price_initial ? parseFloat(utils.formatUnits(res.result.price_initial, QUOTE_DECIMAL)) : 0,
                price_final: res.result.price_final ? parseFloat(utils.formatUnits(res.result.price_final, QUOTE_DECIMAL)) : 0,
            }
        }

        return null;
    },

    getPool: async (pool_id: string) : Promise<Pool|null> => {
        const contractId = getContractObj(pool_id).contractId;
        const provider = AccountStore.getters.getProvider();

        const contract = new Contract({
            id: contractId,
            abi: contractAbi,
            provider,
        });

        const res = await contract.functions.getPool({
            pool_id: pool_id
        });

        if (res.result) {
            const entry_currency = res.result.entry_currency
            const token_decimal = getContractObj(pool_id).entryCurrency.decimal;

            return {
                pool_id: res.result.pool_id,
                base_currency: res.result.base_currency,
                quote_currency: res.result.quote_currency,
                entry_currency,
                status: res.result.status,
                start_timestamp: parseInt(res.result.start_timestamp),
                end_timestamp: parseInt(res.result.end_timestamp),
                duration: parseInt(res.result.duration) ?? 0,
                royalty: parseInt(res.result.royalty) ?? 0,
                long_total: res.result.long_total ? parseFloat(utils.formatUnits(res.result.long_total, token_decimal)) : 0,
                short_total: res.result.short_total ? parseFloat(utils.formatUnits(res.result.short_total, token_decimal)) : 0,
                price_initial: res.result.price_initial ? parseFloat(utils.formatUnits(res.result.price_initial, QUOTE_DECIMAL)) : 0,
                price_final: res.result.price_final ? parseFloat(utils.formatUnits(res.result.price_final, QUOTE_DECIMAL)) : 0,
            };
        }

        return null;
    },
}

export default {
    state,
    actions,
    getters
}