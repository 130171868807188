import {Text, Box, HStack, Icon} from "@chakra-ui/react";
import {
    POOL_STATUS_CLOSED,
    POOL_STATUS_SKIPPED,
    ENTRY_SIDE_LONG,
    POOL_STATUS_PROGRESS, POOL_STATUS_SUSPENDED, POOL_STATUS_OPEN
} from "../Constants";
import {useTranslation} from "react-i18next";
import {FiArrowDown, FiArrowUp, FiDisc} from "react-icons/fi";
import {formatCurrency, getContractObj, timestampToString} from "../utils/Functions";
import {useNavigate} from "react-router-dom";
import { EntryFull } from "../utils/Interfaces";
import PoolStore from "../store/PoolStore";
import { useHookstate } from "@hookstate/core";

export const EntryListRow = (props: {
    entry: EntryFull
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const entry_currency = useHookstate(PoolStore.state.nested(props.entry.pool_id).entry_currency).get();

    const claimable = ((props.entry.pool_status === POOL_STATUS_SKIPPED || props.entry.pool_status === POOL_STATUS_SUSPENDED) && !props.entry.refunded) ||
        (props.entry.pool_status === POOL_STATUS_CLOSED && props.entry.winner && !props.entry.rewarded);

    return (
        <HStack
            width={"100%"}
            onClick={() => navigate(`/pool/${props.entry.pool_id}`)}
            cursor={"pointer"}
            shadow={"dark-lg"}
            borderRadius={'md'}
            bg={'gray.700'}
            p={3}
            justify={"space-between"}
            borderColor={"cyan.300"}
            borderWidth={claimable ? 1 : 0}
        >
            <Box>
                <Text fontSize={'xs'} color={'gray.400'}>{timestampToString(props.entry.timestamp)}</Text>
                <HStack gap={1}>
                    {props.entry.side === ENTRY_SIDE_LONG ?
                        <Icon as={FiArrowUp} color={'green.400'} boxSize={5} /> :
                        <Icon as={FiArrowDown} color={'red.400'} boxSize={5} />
                    }
                    <Text fontSize={'sm'} fontWeight={"bold"}>
                        {formatCurrency(props.entry?.amount)} {getContractObj(props.entry.pool_id).entryCurrency.symbol}
                    </Text>
                </HStack>
            </Box>

            <Box textAlign={"right"}>
                {props.entry.pool_status === POOL_STATUS_CLOSED && props.entry.winner &&
                    <Box>
                        <Text fontSize={'xs'} color={claimable ? 'cyan.300' : 'gray.400'}>
                            { props.entry?.rewarded ? t('reward'): t('claimReward') }
                        </Text>
                        <Text fontSize={'sm'} fontWeight={"bold"}>
                            +{formatCurrency(props.entry?.amount_reward)} {getContractObj(props.entry.pool_id).entryCurrency.symbol}
                        </Text>
                    </Box>
                }

                {props.entry.pool_status === POOL_STATUS_CLOSED && !props.entry.winner &&
                    <Box>
                        <Text fontSize={'xs'} color={'gray.400'}>{t('loss')}</Text>
                        <Text fontSize={'sm'} fontWeight={"bold"}>
                            -{formatCurrency(props.entry?.amount)} {getContractObj(props.entry.pool_id).entryCurrency.symbol}
                        </Text>
                    </Box>
                }

                { (props.entry.pool_status === POOL_STATUS_SKIPPED || props.entry.pool_status === POOL_STATUS_SUSPENDED) &&
                    <Box>
                        <Text fontSize={'xs'} color={claimable ? 'cyan.300' : 'gray.400'}>
                            { props.entry?.refunded ? t('refund'): t('claimRefund') }
                        </Text>
                        <Text fontSize={'sm'} fontWeight={"bold"}>
                            {formatCurrency(props.entry?.amount)} {getContractObj(props.entry.pool_id).entryCurrency.symbol}
                        </Text>
                    </Box>
                }

                { (props.entry.pool_status === POOL_STATUS_OPEN || props.entry.pool_status === POOL_STATUS_PROGRESS) &&
                    <Box>
                        <HStack gap={1}>
                            <FiDisc color={"red"} size={20}/>
                            <Text fontSize={"xs"}>{t('live')}</Text>
                        </HStack>
                    </Box>
                }

            </Box>
        </HStack>
    );
}