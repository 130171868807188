import { VStack, Heading, Text, Spinner } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import { Wrapper } from "../components/Wrapper";
import { CONTRACTS, POLLING_INTERVAL_GET_POOL } from "../Constants";
import PoolStore from "../store/PoolStore";
import { useEffect, useState } from "react";
import { PoolListRow } from "../components/PoolListRow";

export const Home = () => {
    const { t } = useTranslation();
    const [latestPools, setLatestPools] = useState<Record<string, any>>({});

    const loadLatestPools = async () => {
        let latestPools: Record<string, any> = {};
        for (let contractName of Object.keys(CONTRACTS)) {
            try {
                latestPools[contractName] = await PoolStore.getters.getLatestPool(contractName);
            } catch (e) {
                console.error(e);
            }
        }
        setLatestPools(latestPools);
    }

    useEffect(() => {
        loadLatestPools();
        const refreshPoolInterval = setInterval(() => {
            loadLatestPools();
        }, POLLING_INTERVAL_GET_POOL);

        return () => clearInterval(refreshPoolInterval);
    }, []);

    if (Object.keys(latestPools).length === 0) {
        return (
            <Wrapper>
                <VStack>
                    <Spinner />
                </VStack>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <VStack spacing={6}>

                <Heading size={"lg"} textAlign={'center'}>
                    {t('poolsTitle')}<br />
                    <Text color={"pink.500"}>{t('poolsSubtitle')}</Text>
                </Heading>

                <VStack
                    borderRadius={'md'}
                    border={"1px"}
                    borderColor={"whiteAlpha.300"}
                    p={3}
                    w={'100%'}
                    spacing={3}
                >
                    <Text color={"gray.400"} textAlign={'center'}>{t('pools')}</Text>

                    {
                        Object.keys(CONTRACTS).map(contract => {
                            if (latestPools[contract] && latestPools[contract].pool_id) {
                                return <PoolListRow pool={latestPools[contract]} key={latestPools[contract].pool_id} />;
                            }
                        })
                    }
                </VStack>
            </VStack>
        </Wrapper>
    )
}

