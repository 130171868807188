import {
    Image,
    HStack,
    Heading,
    Text
} from "@chakra-ui/react";
import { durationToString, getContractObj } from "../utils/Functions";

export const PoolTitle = (props: {
    pool_id: string,
    base_currency: string,
    quote_currency: string,
    poolDuration: number
}) => {

    return (
        <HStack>
            <Image h={5} src={getContractObj(props.pool_id).logo}></Image>
            <Heading size={'sm'}>{`${props.base_currency}/${props.quote_currency}`}</Heading>
            <Text>{durationToString(props.poolDuration)}</Text>
        </HStack>
    );
}