import { Box, HStack } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import PoolStore from "../store/PoolStore";
import {useEffect, useState} from "react";


export const PoolSentiment = (props: {
    pool_id: string
}) => {
    const short_total = useHookstate(PoolStore.state.nested(props.pool_id).short_total).get();
    const long_total = useHookstate(PoolStore.state.nested(props.pool_id).long_total).get();

    const [shortSentiment, setShortSentiment] = useState(50);
    const [longSentiment, setLongSentiment] = useState(50);

    useEffect(() => {
        const total = long_total + short_total;
        setShortSentiment((100*short_total)/total);
        setLongSentiment((100*long_total)/total);
    }, [long_total, short_total])


    return (
        <HStack gap={0.5}>
            <Box background={"green.400"} h={1} w={`${longSentiment}%`}/>
            <Box background={"red.400"} h={1} w={`${shortSentiment}%`}/>
        </HStack>
    )
}
