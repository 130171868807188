import { IconButton } from "@chakra-ui/button";
import {useEffect, useState} from "react";
import { FiShare2 } from "react-icons/fi";
import { useScreenshot } from "use-screenshot-hook";
import Bg1 from '../assets/bg1.png';
import {isMobileOrTabletDevice} from "../utils/Functions";

export const ShareButton = (props: {
    contentRef: any
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { image, takeScreenshot } = useScreenshot({ ref: props.contentRef });

    useEffect(() => {
        if (image) {
            shareImage(image);
        }
    }, [image])

    const snapshot = async () => {
        props.contentRef.current.style.backgroundImage=`url(${Bg1})`;
        props.contentRef.current.style.backgroundRepeat='no-repeat';
        props.contentRef.current.style.backgroundSize='cover';
        props.contentRef.current.style.backgroundPosition='top center';
        await takeScreenshot();
        props.contentRef.current.style.backgroundImage = 'none';
    };

    const shareImage = async (base64image: string) => {
        setIsLoading(true);
        try {
            if (isMobileOrTabletDevice() && navigator?.share) {
                const blob = await (await fetch(base64image)).blob();
                const file = new File([blob], 'entry.png', {type: blob.type});
                const shareData = {
                    files: [file],
                    url: 'https://prediction.kuku.games',
                    title: 'Kuku Games - Prediction',
                    text: 'This is my result on @kukudotgames price prediction game!'
                };

                if (navigator.canShare(shareData)) {
                    navigator.share(shareData)
                }
            } else {
                downloadFile(base64image);
            }
        }
        catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    const downloadFile = (base64image: string) => {
        try {
            const a = document.createElement("a");
            a.href = base64image;
            a.download = "entry.png";
            a.click();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <IconButton
            variant='outline'
            aria-label='Capture'
            icon={<FiShare2 />}
            isLoading={isLoading}
            onClick={snapshot}
        />
    );
}