import {
    Flex,
    Box,
    Stack,
    Image,
    ButtonGroup
} from '@chakra-ui/react';

import Logo from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { ConnectButton } from './ConnectButton';
import { HistoryButton } from "./HistoryButton";

export default function Header() {
    const navigate = useNavigate();

    return (
        <Box w={'100vw'} py={3} px={4}>
            <Flex justifyContent={'space-between'}>
                
                <Image h={8} src={Logo} onClick={() => navigate("/")} cursor={"pointer"} zIndex={2000}/>
                
                <Flex alignItems={'center'}>
                    <Stack direction={'row'}>
                        <ButtonGroup size='sm' isAttached variant='outline'>
                            <HistoryButton />
                            <ConnectButton />
                        </ButtonGroup>
                    </Stack>
                </Flex>
            </Flex>
        </Box>
    );
}