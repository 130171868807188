import { hookstate } from "@hookstate/core"
import { Contract, utils } from "koilib"
import { TransactionJsonWait, TransactionReceipt } from "koilib/lib/interface";
import { none } from '@hookstate/core'
import { ENTRIES_PAGINATION_LIMIT, ENTRY_SIDE_LONG, ENTRY_SIDE_SHORT } from "../Constants";
import AccountStore from "./AccountStore";
import { durationToString, getContractObj, getEstimatedProfit } from "../utils/Functions";
import { Entry, EntryFull } from "../utils/Interfaces";
import ContractAbi from "../utils/ContractAbi";
import PoolStore from "./PoolStore";

type EntryStoreType = Record<string, Entry>;

const state = hookstate<EntryStoreType>({});

const actions = {

    refund: async (props: {
        pool_id: string,
    }) => {
        const contractId = getContractObj(props.pool_id).contractId;
        const signer = AccountStore.getters.getSigner();

        if (!signer) {
            throw "Signer unavailable";
        }

        const contract = new Contract({
            id: contractId,
            abi: ContractAbi,
            signer
        });

        return await contract.functions.refund({
            user: AccountStore.getters.getAccount(),
            pool_id: props.pool_id
        });
    },

    reward: async (props: {
        pool_id: string,
    }) => {
        const contractId = getContractObj(props.pool_id).contractId;
        const signer = AccountStore.getters.getSigner();

        if (!signer) {
            throw "Signer unavailable";
        }

        const contract = new Contract({
            id: contractId,
            abi: ContractAbi,
            signer
        });

        return await contract.functions.reward({
            user: AccountStore.getters.getAccount(),
            pool_id: props.pool_id
        });
    },

    entry: async (props: {
        pool_id: string,
        side: string,
        amount: string
    }) => {
        const signer = AccountStore.getters.getSigner();
        if (!signer) {
            throw "Signer unavailable";
        }

        const contractId = getContractObj(props.pool_id).contractId;
        const address = AccountStore.getters.getAccount();

        const token_decimal = getContractObj(props.pool_id).entryCurrency.decimal;
        const amount = utils.parseUnits(props.amount, token_decimal);

        const contract = new Contract({
            id: contractId,
            abi: ContractAbi,
            signer,
            provider: AccountStore.getters.getProvider()
        });

        return await contract.functions.entry({
            user: address,
            amount,
            side: props.side
        });

        /*const kukuContract = new Contract({
            id: KUKU_CONTRACT_ID,
            signer,
            abi: KukuAbi
        });

        const contract = new Contract({
            id: contractId,
            abi: ContractAbi,
            signer
        });

        const {operation: entry} = (((await contract.functions.entry({
            user: address,
            amount,
            side: props.side,
            sign_as: address
        }, {
            sendTransaction: false,
            onlyOperation: true
        }))));
        
        return await (((kukuContract.functions.approve({
            owner: address,
            spender: contractId,
            value: amount
        }, {
            sendTransaction: true,
            nextOperations: [entry]
        }))));
         */
    },

    confirmTransaction: async (props: {
        pool_id: string,
        transaction: TransactionJsonWait,
        receipt: TransactionReceipt
    }) => {
        

        if (props.transaction != null) {
            if (props.receipt?.logs) {
                console.log(`Transfer failed. Logs: ${props.receipt.logs.join(",")}`);
                throw new Error(props.receipt.logs.join(","))
            } else {
                const {blockNumber} = await props.transaction.wait();
                console.log(`Transaction mined. Block number: ${blockNumber}`);

                actions.refreshEntry(props.pool_id);

                const pool_entry_currency = PoolStore.state.nested(props.pool_id).entry_currency.get();
                AccountStore.actions.refreshBalance(props.pool_id);
                PoolStore.actions.refreshPool(props.pool_id);
            }
        }

        return true;
    },

    async refreshEntry(pool_id: string) {
        const entry = await getters.getEntry(pool_id);
        if (entry) {
            state.merge({
                [pool_id]: entry
            })
        } else {
            state.merge({
                [pool_id]: none
            })
        }
    },

    async notify(props:{
        pool_id: string,
        amount: string,
        side: string
    }) {

        const { amount, pool_id, side } = props;

        await PoolStore.actions.refreshPool(pool_id);

        const pool = PoolStore.state.nested(pool_id).get();
        const pool_name = `${pool.base_currency}/${pool.quote_currency} ${durationToString(pool.duration)}`;
        const entrySymbol = getContractObj(pool_id).entryCurrency.symbol;
        const profit = side === ENTRY_SIDE_SHORT ? 
            getEstimatedProfit({ wanted: pool.long_total, against: pool.short_total }) :
            getEstimatedProfit({ wanted: pool.short_total, against: pool.long_total });

const message = `
🚀 *New prediction entry!*

💰 ${pool_name}
💵 ${amount} $${entrySymbol}
${side === ENTRY_SIDE_LONG ? '⬆️ LONG' : '⬇️ SHORT'}

📈 *ESTIMATED PROFIT FOR ${side === ENTRY_SIDE_LONG ? 'SHORT' : 'LONG'}s is ${profit}%*

[▶️ MAKE YOUR ENTRY NOW!](https://prediction.kuku.games/pool/${pool_id})

.

`;

        fetch(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/sendAnimation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                chat_id: process.env.REACT_APP_TELEGRAM_CHAT_ID,
                caption: message,
                animation: process.env.REACT_APP_TELEGRAM_GIF,
                parse_mode: 'Markdown',
            }),
        });
    }
};

const getters = {

    getEntry: async (pool_id: string) : Promise<Entry|null> => {
        const contractId = getContractObj(pool_id).contractId;
        const provider = AccountStore.getters.getProvider();
        const user = AccountStore.getters.getAccount();

        if (user) {
            const contract = new Contract({
                id: contractId,
                abi: ContractAbi,
                provider,
            });
    
            const res = await contract.functions.getEntry(
                {
                    user: user,
                    pool_id: pool_id
                }
            );

            if (res.result && res.result.value) {
                const token_decimal = getContractObj(pool_id).entryCurrency.decimal;

                return {
                    amount: parseFloat(utils.formatUnits(res.result.value.amount, token_decimal)),
                    amount_reward: res.result.value.amount_reward ? parseFloat(utils.formatUnits(res.result.value.amount_reward, token_decimal)) : none,
                    side: res.result.value.side,
                    winner: res.result.value.winner,
                    refunded: res.result.value.refunded,
                    rewarded: res.result.value.rewarded,
                    timestamp: parseInt(res.result.value.timestamp)
                }
            }
        }

        return null;
    },

    getEntries: async (contractId: string, offsetPoolId: string) : Promise<EntryFull[]> => {
        const provider = AccountStore.getters.getProvider();
        const user = AccountStore.getters.getAccount();

        if (user) {
            const contract = new Contract({
                id: contractId,
                abi: ContractAbi,
                provider,
            });

            const res = await contract.functions.getEntries(
                {
                    user: user,
                    offset_pool_id: offsetPoolId,
                    limit: ENTRIES_PAGINATION_LIMIT,
                    direction: 1
                }
            );

            if (res.result && res.result.entries) {
                let entries = [];
                for (let entry of res.result.entries) {

                    const token_decimal = getContractObj(entry.pool_id).entryCurrency.decimal;

                    entries.push({
                        pool_id: entry.pool_id,
                        pool_status: entry.pool_status,
                        amount: entry.amount ? parseFloat(utils.formatUnits(entry.amount, token_decimal)) : none,
                        amount_reward: entry.amount_reward ? parseFloat(utils.formatUnits(entry.amount_reward, token_decimal)) : none,
                        side: entry.side,
                        winner: entry.winner,
                        refunded: entry.refunded,
                        rewarded: entry.rewarded,
                        timestamp: parseInt(entry.timestamp)
                    });
                }
                return entries;
            }
        }

        return [];
    },
}

export default {
    state,
    actions,
    getters
}