import { Box, Text } from "@chakra-ui/react";
import { ENTRY_SIDE_LONG } from "../Constants";
import { useHookstate } from "@hookstate/core";
import PoolStore from "../store/PoolStore";
import useColorChange from "use-color-change";
import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { getEstimatedProfit } from "../utils/Functions";

export const EstimatedProfitDetail = (props: {
    pool_id: string,
    side: string
}) => {
    const { t } = useTranslation();
    const short_total = useHookstate(PoolStore.state.nested(props.pool_id).short_total);
    const long_total = useHookstate(PoolStore.state.nested(props.pool_id).long_total);
    const [profit, setProfit] = useState(0);

    useEffect(() => {
        const estimatedProfitSideLong = getEstimatedProfit({
            wanted: long_total.get(),
            against: short_total.get()
        });
        const estimatedProfitSideShort = getEstimatedProfit({
            wanted: short_total.get(),
            against: long_total.get()
        });

        setProfit(props.side === ENTRY_SIDE_LONG ? estimatedProfitSideLong : estimatedProfitSideShort);
    }, [long_total, short_total])

    const colorStyle = useColorChange(profit , {
        higher: 'var(--chakra-colors-pink-500)',
        lower: 'var(--chakra-colors-pink-500)',
        duration: 1800
    });

    return (
        <Box>
            <Text fontSize={'sm'} color={'gray.400'}>{t('reward')}</Text>
            <Text sx={colorStyle} fontSize={'lg'} fontWeight={"bold"}>
                +{(profit).toFixed(2)}%
            </Text>
        </Box>
    )
}
