import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    fonts: {
        heading: `'Comfortaa', sans-serif`,
        body: `'Comfortaa', sans-serif`,
    },
    radii: {
        md: '1em'
    },
    space: {
        '4': '20px',
        '200': '10px'
    },
    colors: {
        gray: {
            '700': '#221e41'
        }
    }
});

export default theme;