import { WalletAdapter } from "./Interfaces";
import * as kondor from "kondor-js";
import { Signer, Provider } from "koilib";

const LOCAL_STORAGE_KEY = "KONDOR";

const kondorAdapter : WalletAdapter = {

    async init(config) {
        if (config?.onSessionDelete) {
            //WCK.web3Modal.onSessionDelete(() => config.onSessionDelete);
        }

        if (config?.onSessionExpire) {
            //WCK.web3Modal.onSessionExpire(() => config.onSessionExpire);
        }
    },

    async login() {
        const session = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (session) {
            return true;
        }

        return false;
    },

    async connect() {
        try {

            const session = window.localStorage.getItem(LOCAL_STORAGE_KEY);
            if (session) {
                return session;
            }

            const accounts = await kondor.getAccounts();
            if (accounts.length >= 1) {
                const address = accounts[0].address;
                window.localStorage.setItem(LOCAL_STORAGE_KEY, address);
                return address;
            }
            
        } catch (e) {
            console.error(e);
        }

        return null;
    },

    async disconnect() {
        try {
            //await WCK.disconnect();
            window.localStorage.removeItem(LOCAL_STORAGE_KEY);
            return true;
        } catch (e) {
            console.error(e);
        }

        return false;
    },

    getSigner(address: string) {
        return kondor.getSigner(address) as Signer;
    }
}

export default kondorAdapter;