import { VStack, Heading, Text, Button, Select, Spinner } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import { Wrapper } from "../components/Wrapper";
import { CONTRACTS } from "../Constants";
import { durationToString } from "../utils/Functions";
import { useEffect, useState } from "react";
import { useHookstate } from "@hookstate/core";
import AccountStore from "../store/AccountStore";
import {EntryListRow} from "../components/EntryListRow";
import { EntryFull } from "../utils/Interfaces";
import EntryStore from "../store/EntryStore";

export const Entries = () => {
    const account = useHookstate(AccountStore.state.account);
    const { t } = useTranslation();
    const [contract, setContract] = useState(Object.keys(CONTRACTS)[0]);
    const [offsetPoolId, setOffsetPoolId] = useState("");
    const [entries, setEntries] = useState<EntryFull[]>([]);
    const [loading, setLoading] = useState(false);

    const loadEntries = async (offset: string, reset: boolean) => {
        try {
            setLoading(true);
            if (contract) {
                const contractId = CONTRACTS[contract].contractId;
                const userEntries: EntryFull[] = await EntryStore.getters.getEntries(contractId, offset);
                if (userEntries.length > 0 && reset) {
                    setEntries(userEntries);
                    setOffsetPoolId(userEntries[userEntries.length - 1].pool_id);
                } else if (userEntries.length > 0 && !reset) {
                    setEntries(entries.concat(userEntries));
                    setOffsetPoolId(userEntries[userEntries.length - 1].pool_id);
                } else if(reset) {
                    setEntries([]);
                    setOffsetPoolId("");
                }
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    useEffect(() => {
        loadEntries("", true);
    }, [account, contract]);

    /*useEffect(() => {
        setEntries([]);
        loadEntries();
    }, [offsetPoolId]);*/

    return (
        <Wrapper>
            <VStack spacing={6}>

                <Heading size={"lg"} textAlign={'center'}>
                    {t('history')}<br />
                </Heading>

                <Select value={contract} onChange={(ev) => setContract(ev.target.value)}>
                    {
                        Object.keys(CONTRACTS).map(contract => {
                            const base_currency = contract.split("_")[0];
                            const quote_currency = contract.split("_")[1];
                            const duration = durationToString(parseInt(contract.split("_")[2]), t);
                            return <option value={contract} key={contract}>{base_currency}/{quote_currency} - {duration}</option>;
                        })
                    }
                </Select>

                <VStack
                    borderRadius={'md'}
                    border={"1px"}
                    borderColor={"whiteAlpha.300"}
                    p={3}
                    w={'100%'}
                    spacing={3}
                >

                    { 
                        loading && entries.length == 0 && 
                        <Spinner/> 
                    }

                    {
                        !loading && entries.length === 0 &&
                        <Text>{t('no_entries')}</Text>
                    }

                    {
                        entries.length > 0 &&
                        entries.map(entry => {
                            if (entry && entry.pool_id) {
                                return (
                                   <EntryListRow entry={entry} key={entry.pool_id} />
                                );
                            }
                        })
                    }

                    {
                        entries.length > 0 &&
                        <VStack>
                            <Button
                                variant='outline'
                                w={"100%"}
                                borderColor={'gray.100'}
                                color={'gray.100'}
                                onClick={() => loadEntries(offsetPoolId, false)}
                                isLoading={loading}
                            >
                                {t('loadMore')}
                            </Button>
                        </VStack>
                    }
                </VStack>
            </VStack>
        </Wrapper>
    )
}

