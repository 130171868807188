import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import AccountStore from "../store/AccountStore";
import { useHookstate } from "@hookstate/core";
import { useEffect, useRef } from "react"
import { POOL_STATUS_CLOSED, POOL_STATUS_OPEN, POOL_STATUS_PROGRESS } from "../Constants";
import { EntryButtonsAndModal } from './EntryButtonsAndModal';
import { EntryPlaced } from './EntryPlaced';
import { PoolReport } from "./PoolReport";
import { PoolStatus } from "./PoolStatus";
import { PoolTitle } from "./PoolTitle";
import Bg2 from '../assets/bg2.png';
import EntryStore from "../store/EntryStore";
import PoolStore from "../store/PoolStore";
import { useTranslation } from 'react-i18next';
import { PoolSentiment } from './PoolSentiment';
import { FaChartLine } from "react-icons/fa";
import { IconButton } from '@chakra-ui/react'
import { Chart } from './Chart';
import { ShareButton } from './ShareButton';

export default function PoolBox(props: {
    pool_id: string
}) {
    const { pool_id } = props;
    const account = useHookstate(AccountStore.state.account).get();
    const poolDuration = useHookstate(PoolStore.state.nested(props.pool_id).duration).get();
    const pool_base_currency = useHookstate(PoolStore.state.nested(props.pool_id).base_currency).get();
    const pool_quote_currency = useHookstate(PoolStore.state.nested(props.pool_id).quote_currency).get();
    const pool_status = useHookstate(PoolStore.state.nested(props.pool_id).status).get();
    const pool_entry_currency = useHookstate(PoolStore.state.nested(props.pool_id).entry_currency).get();
    const entry = useHookstate(EntryStore.state.nested(props.pool_id)).get();
    const ref = useRef(null)

    useEffect(() => {
        AccountStore.actions.refreshBalance(pool_id);
    }, [account]);

    return (
        <Box
            ref={ref}
            borderRadius={'md'}
            border={"1px"}
            borderColor={"whiteAlpha.300"}
            my={'auto'}
            mx={'auto'}
            w={{ base: "100%", sm: "100%", md: "500px", lg: "500px" }}
            minW={"300px"}
            p={3}
        >
            <Box shadow={"dark-lg"} borderRadius={'md'}>
                <Box
                    backgroundImage={`url(${Bg2})`}
                    backgroundRepeat={'no-repeat'}
                    backgroundSize={'cover'}
                    backgroundPosition={'center center'}
                    borderTopRadius={'md'}
                >
                    <HStack p={3} justifyContent={"space-between"}>
                        <PoolTitle 
                            pool_id={props.pool_id} 
                            poolDuration={poolDuration}
                            base_currency={pool_base_currency} 
                            quote_currency={pool_quote_currency}
                        />
                        <HStack gap={1}>
                            { [POOL_STATUS_OPEN, POOL_STATUS_PROGRESS].includes(pool_status) &&
                                <ChartButton pool_id={props.pool_id}/>
                            }
                            { entry && entry.amount > 0 &&
                                <ShareButton contentRef={ref}/>
                            }
                        </HStack>
                    </HStack>
                    <Box px={3} pb={3}>
                        <PoolReport pool_id={pool_id} />
                    </Box>
                </Box>

                <PoolSentiment pool_id={props.pool_id}/>

                <Box
                    p={3}
                    bg={'whiteAlpha.100'}
                    borderBottomRadius={'md'}
                >
                    <PoolStatus pool_id={pool_id} />
                </Box>

            </Box>

            <Box pt={4}>
                <EntrySection pool_id={pool_id} />
            </Box>

        </Box>
    );
}

const EntrySection = (props: {
    pool_id: string
}) => {
    const entry = useHookstate(EntryStore.state.nested(props.pool_id)).get();
    const pool_status = useHookstate(PoolStore.state.nested(props.pool_id).status);
    const account = useHookstate(AccountStore.state.account);
    const { t } = useTranslation();

    useEffect(() => {
        if (account || [POOL_STATUS_CLOSED, POOL_STATUS_OPEN].includes(pool_status.get())) {
            EntryStore.actions.refreshEntry(props.pool_id);
        }
    }, [account, pool_status]);

    return (
        <>
            {pool_status.get() === POOL_STATUS_OPEN && !entry?.amount &&
                <EntryButtonsAndModal pool_id={props.pool_id} />
            }

            {pool_status.get() === POOL_STATUS_PROGRESS && !entry?.amount &&
                <VStack p={4}>
                    <Text>{t('entryClosed')}</Text>
                </VStack>
            }

            {
                entry?.amount &&
                <EntryPlaced pool_id={props.pool_id} />
            }
        </>
    )
}

const ChartButton = (props: {
    pool_id: string
}) => {
    return (
        <Chart pool_id={props.pool_id} button={
            <IconButton
                variant='outline'
                aria-label='Chart'
                icon={<FaChartLine />}
            />
        }/>
    );
}

